<template>
  <main>
    <div class="contenu4">

      <div style="position:relative;">
        <!-- mobile -->
        <div class="logo mobile">
          <router-link to="/"><img src="../assets/images/logo.png"></router-link>
        </div>
        <img class="top-mobile mobile" src="../assets/images/top-vague3-mobile.jpg">
        <!-- commun -->
        <div class="top-pourcent"><img src="../assets/images/100pourcent.png"></div>
      </div>
      <div class="dota-wrapper">
        <div class="top-carousel">
          <div>
            <!-- <img src="../assets/images/acheter.png"> -->
            <h3>achetez</h3>
          </div>
          <div class="etgagner desktop">
            <h3>et gagnez</h3>
            <!-- <img src="../assets/images/gagnez.png"> -->
          </div>
        </div>

        <agile class="carousel">
          <div class="slide">
            <div class="sl1">
              <img src="/images/caisse.png">
              <p>la Caisse enregistreuse</p>
            </div>
            <div class="sl2"><img src="../assets/images/egale.png"></div>
            <!-- <img class="mobile gg" src="../assets/images/gagnez.png"><br />-->
            <div class="sl3">
              <div v-if="s1" @click="s1=false" class="sl3-infos help3">
                Un e-coffret Wonderbox Ateliers de cuisine d’une valeur unitaire de 79,90€ TTC.<br /> Le code reçu par email donne droit à un bon d’achat dématérialisé d’un montant prédéfini permettant d’acheter directement sur le site <a href="https://www.wonderbox.fr" target="_blank">www.wonderbox.fr</a>, l’e-coffret gagné. Le code peut être utilisé pour un autre e-coffret d’un montant inférieur ou égal. Si le gagnant souhaite opter pour un autre e-coffret d’une valeur supérieure à l’e-coffret gagné, tous frais supplémentaires seront à la charge du gagnant. Si le montant est inférieur, le crédit restant pourra être conservé pour d’autres commandes jusqu’à la date limite d’utilisation du code. Le code est valable jusqu’au 31/03/2024. Le code est à renseigner au moment du paiement, en choisissant 
                « paiement par Wondercard ». Le code n’est ni échangeable, ni prolongeable.
                Voir les termes et conditions complètes d’utilisation des e-coffrets sur <a href="https://www.wonderbox.fr/cgv.html" target="_blank">www.wonderbox.fr/cgv.html</a>.
              </div>
              <p>et gagnez</p><img @click="s1=true" src="/ballon2.png">
            </div>
            <div class="sl4">
              <!-- <img src="../assets/images/ou.png"> -->
              <p>OU</p>
            </div>
            <div class="sl5">
                <div v-if="s8" @click="s8=false" class="sl5-infos">
                Le code reçu vous donne droit à un Livre Photo Style Carré 20x20 cm (ou Livre Photo Couverture Souple carré 20x20cm) de 24 pages GRATUIT, ou -19,95€ sur les autres livres photo, hors album de poche, avec la livraison en Mondial Relay comprise en France Métropolitaine ou -3,99€ sur un autre transporteur. Hors options et pages supplémentaires et uniquement sur Photoweb.fr. Offre non cumulable avec d’autres offres promotionnelles en cours, non compatible avec les tarifs dégressifs. Code unique et personnel valable une seule fois par foyer (même nom, adresse mail et postale), jusqu’au 31/07/2021.   
               <!-- Un Livre Photo Style Carré 20x20 cm de 24 pages GRATUIT, ou -19,95€ sur les autres livres photo, hors Album de poche, avec la livraison en Mondial Relay comprise en France Métropolitaine ou -3,99€ sur un autre transporteur.
                <br /><br />
                Hors options et pages supplémentaires et uniquement sur Photoweb.fr. Offre non cumulable avec d'autres offres promotionnelles en cours, non compatible avec les tarifs dégressifs. Code unique et personnel valable une seule fois par foyer (même nom, adresse mail et postale), jusqu'au 31/07/2021.
                <br /><br />Pour en savoir plus <a href="https://www.photoweb.fr" target="_blank">www.photoweb.fr</a>. -->
              </div>
            <img @click="s8=true"  src="/ballon1.png">
            </div>
          </div>
          <div class="slide">
            <div class="sl1"><img src="/images/dentistes.png">
              <p>le dentiste / cabinet dentaire</p>
            </div>
            <div class="sl2"><img src="../assets/images/egale.png"></div>
            <div class="sl3">
              <div v-if="s2" @click="s2=false" class="sl3-infos">
                Un e-coffret LaShootingBOX Photo de Famille d’une valeur unitaire de 149€ TTC.<br />
                Le code reçu par email donne droit à une séance photo en famille d’une heure. Le code doit être activé
                sur le site <a href="https://www.lashootingbox.com" target="_blank">www.lashootingbox.com</a>.<br />
                Si le gagnant souhaite opter pour un autre e-coffret, il peut être échangé sur le site de LaShootingBOX.
                En revanche la date de validité ne changera pas. Le code est valable jusqu’au 30/06/2021.<br />
                Voir les termes et conditions complètes d’utilisation des e-coffrets sur
                <a href="https://www.lashootingbox.com/fr/conditions-generales-de-vent" target="_blank">www.lashootingbox.com/fr/conditions-generales-de-vent</a><br />
              </div>
              <p>et gagnez</p>
              <img @click="s2=true" src="../assets/images/ballon-shooting.png">
            </div>
            <div class="sl4">
              <!-- <img src="../assets/images/ou.png"> -->
              <p>OU</p>
            </div>
            <div class="sl5">
                <div v-if="s8" @click="s8=false" class="sl5-infos">
                Le code reçu vous donne droit à un Livre Photo Style Carré 20x20 cm (ou Livre Photo Couverture Souple carré 20x20cm) de 24 pages GRATUIT, ou -19,95€ sur les autres livres photo, hors album de poche, avec la livraison en Mondial Relay comprise en France Métropolitaine ou -3,99€ sur un autre transporteur. Hors options et pages supplémentaires et uniquement sur Photoweb.fr. Offre non cumulable avec d’autres offres promotionnelles en cours, non compatible avec les tarifs dégressifs. Code unique et personnel valable une seule fois par foyer (même nom, adresse mail et postale), jusqu’au 31/07/2021.   
               <!-- Un Livre Photo Style Carré 20x20 cm de 24 pages GRATUIT, ou -19,95€ sur les autres livres photo, hors Album de poche, avec la livraison en Mondial Relay comprise en France Métropolitaine ou -3,99€ sur un autre transporteur.
                <br /><br />
                Hors options et pages supplémentaires et uniquement sur Photoweb.fr. Offre non cumulable avec d'autres offres promotionnelles en cours, non compatible avec les tarifs dégressifs. Code unique et personnel valable une seule fois par foyer (même nom, adresse mail et postale), jusqu'au 31/07/2021.
                <br /><br />Pour en savoir plus <a href="https://www.photoweb.fr" target="_blank">www.photoweb.fr</a>. -->
              </div>
            <img @click="s8=true" src="/ballon1.png">
            </div>
          </div>
          <div class="slide">
            <div class="sl1"><img src="/images/coiffeur.png">
              <p>Le Coiffeur / Coiffeur Créatif</p>
            </div>
            <div class="sl2"><img src="../assets/images/egale.png"></div>
            <!-- <img class="mobile gg" src="../assets/images/gagnez.png"><br />-->
            <div class="sl3">
              <div v-if="s3" @click="s3=false" class="sl3-infos">
                Un bon pour une séance coiffure en Famille Coiff&Co d’une valeur unitaire de 70€ TTC.<br />
                Le bon reçu par email vous permet de bénéficier d'une séance de relooking coiffure comprenant un forfait
                shampooing, coupe, coiffage, en famille pour 4 personnes.
                La durée de validité de votre bon est valable 1 fois en salon et non cumulable avec d’autres promotions,
                jusqu’au 30/06/2021. <br />
                Lors de votre visite dans l’un de nos salons, présentez votre bon imprimé ou en format dématérialisé,
                pour bénéficier de l'offre.
              </div>
              <p>et gagnez</p><img @click="s3=true" src="../assets/images/ballon-coiffure.png">
            </div>
            <div class="sl4">
              <!-- <img src="../assets/images/ou.png"> -->
              <p>OU</p>
            </div>
            <div class="sl5">
                                <div v-if="s8" @click="s8=false" class="sl5-infos">
                Le code reçu vous donne droit à un Livre Photo Style Carré 20x20 cm (ou Livre Photo Couverture Souple carré 20x20cm) de 24 pages GRATUIT, ou -19,95€ sur les autres livres photo, hors album de poche, avec la livraison en Mondial Relay comprise en France Métropolitaine ou -3,99€ sur un autre transporteur. Hors options et pages supplémentaires et uniquement sur Photoweb.fr. Offre non cumulable avec d’autres offres promotionnelles en cours, non compatible avec les tarifs dégressifs. Code unique et personnel valable une seule fois par foyer (même nom, adresse mail et postale), jusqu’au 31/07/2021.   
               <!-- Un Livre Photo Style Carré 20x20 cm de 24 pages GRATUIT, ou -19,95€ sur les autres livres photo, hors Album de poche, avec la livraison en Mondial Relay comprise en France Métropolitaine ou -3,99€ sur un autre transporteur.
                <br /><br />
                Hors options et pages supplémentaires et uniquement sur Photoweb.fr. Offre non cumulable avec d'autres offres promotionnelles en cours, non compatible avec les tarifs dégressifs. Code unique et personnel valable une seule fois par foyer (même nom, adresse mail et postale), jusqu'au 31/07/2021.
                <br /><br />Pour en savoir plus <a href="https://www.photoweb.fr" target="_blank">www.photoweb.fr</a>. -->
              </div>          
            <img @click="s8=true" src="/ballon1.png">
            </div>
          </div>
          <div class="slide">
            <div class="sl1"><img src="/images/20pots.png">
              <p>pack de 20 pots</p>
            </div>
            <div class="sl2"><img src="../assets/images/egale.png"></div>
            <!-- <img class="mobile gg" src="../assets/images/gagnez.png"><br />-->
            <div class="sl3">
              <div v-if="s4" @click="s4=false" class="sl3-infos">
                Un e-coffret Wonderbox Parcs d’attractions et zoos d’une valeur unitaire de 89,90€ TTC.
                <br /><br />
                Le e-coffret Wonderbox reçu par email est valable jusqu’au 31/03/2024. Vous pouvez échanger ou prolonger
                gratuitement votre e-coffret, tant que sa date de validité n’est pas dépassée, et autant de fois que
                vous le souhaitez, sauf cas particuliers visés à l’article 7 des conditions générales de vente.
                Vois les termes et conditions complètes sur <a href="https://www.wonderbox.fr/cgv.html" target="_blank">www.wonderbox.fr/cgv.html</a>
              </div>
              <p>et gagnez</p><img @click="s4=true" src="../assets/images/ballon-parc.png">
            </div>
            <div class="sl4">
              <!-- <img src="../assets/images/ou.png"> -->
              <p>OU</p>
            </div>
            <div class="sl5">
                              <div v-if="s8" @click="s8=false" class="sl5-infos">
                Le code reçu vous donne droit à un Livre Photo Style Carré 20x20 cm (ou Livre Photo Couverture Souple carré 20x20cm) de 24 pages GRATUIT, ou -19,95€ sur les autres livres photo, hors album de poche, avec la livraison en Mondial Relay comprise en France Métropolitaine ou -3,99€ sur un autre transporteur. Hors options et pages supplémentaires et uniquement sur Photoweb.fr. Offre non cumulable avec d’autres offres promotionnelles en cours, non compatible avec les tarifs dégressifs. Code unique et personnel valable une seule fois par foyer (même nom, adresse mail et postale), jusqu’au 31/07/2021.   
               <!-- Un Livre Photo Style Carré 20x20 cm de 24 pages GRATUIT, ou -19,95€ sur les autres livres photo, hors Album de poche, avec la livraison en Mondial Relay comprise en France Métropolitaine ou -3,99€ sur un autre transporteur.
                <br /><br />
                Hors options et pages supplémentaires et uniquement sur Photoweb.fr. Offre non cumulable avec d'autres offres promotionnelles en cours, non compatible avec les tarifs dégressifs. Code unique et personnel valable une seule fois par foyer (même nom, adresse mail et postale), jusqu'au 31/07/2021.
                <br /><br />Pour en savoir plus <a href="https://www.photoweb.fr" target="_blank">www.photoweb.fr</a>. -->
              </div>
            <img @click="s8=true" src="/ballon1.png">
            </div>
          </div>
          <div class="slide">
            <div class="sl1"><img src="/images/4pots.png">
              <p>pack de <span class="spfont">4</span> pots</p>
            </div>
            <div class="sl2"><img src="../assets/images/egale.png"></div>
            <!-- <img class="mobile gg" src="../assets/images/gagnez.png"><br />-->

            <div class="sl3">

              <div v-if="s5" @click="s5=false" class="sl3-infos">
                Un e-coffret Wonderbox Parcs d’attractions et zoos d’une valeur unitaire de 89,90€ TTC.
                <br /><br />
                Le e-coffret Wonderbox reçu par email est valable jusqu’au 31/03/2024. Vous pouvez échanger ou prolonger
                gratuitement votre e-coffret, tant que sa date de validité n’est pas dépassée, et autant de fois que
                vous le souhaitez, sauf cas particuliers visés à l’article 7 des conditions générales de vente.
                Vois les termes et conditions complètes sur <a href="https://www.wonderbox.fr/cgv.html" target="_blank">www.wonderbox.fr/cgv.html</a>
              </div>
              <p>et gagnez</p><img @click="s5=true" src="../assets/images/ballon-parc.png">
            </div>
            <div class="sl4">
              <!-- <img src="../assets/images/ou.png"> -->
              <p>OU</p>
            </div>
            <div class="sl5">
                                <div v-if="s8" @click="s8=false" class="sl5-infos">
                Le code reçu vous donne droit à un Livre Photo Style Carré 20x20 cm (ou Livre Photo Couverture Souple carré 20x20cm) de 24 pages GRATUIT, ou -19,95€ sur les autres livres photo, hors album de poche, avec la livraison en Mondial Relay comprise en France Métropolitaine ou -3,99€ sur un autre transporteur. Hors options et pages supplémentaires et uniquement sur Photoweb.fr. Offre non cumulable avec d’autres offres promotionnelles en cours, non compatible avec les tarifs dégressifs. Code unique et personnel valable une seule fois par foyer (même nom, adresse mail et postale), jusqu’au 31/07/2021.   
               <!-- Un Livre Photo Style Carré 20x20 cm de 24 pages GRATUIT, ou -19,95€ sur les autres livres photo, hors Album de poche, avec la livraison en Mondial Relay comprise en France Métropolitaine ou -3,99€ sur un autre transporteur.
                <br /><br />
                Hors options et pages supplémentaires et uniquement sur Photoweb.fr. Offre non cumulable avec d'autres offres promotionnelles en cours, non compatible avec les tarifs dégressifs. Code unique et personnel valable une seule fois par foyer (même nom, adresse mail et postale), jusqu'au 31/07/2021.
                <br /><br />Pour en savoir plus <a href="https://www.photoweb.fr" target="_blank">www.photoweb.fr</a>. -->
              </div>
            <img @click="s8=true" src="/ballon1.png">
            </div>
          </div>
          <div class="slide">
            <div class="sl1"><img src="/images/paillettes.png">
              <p>pack pâte à paillettes</p>
            </div>
            <div class="sl2"><img src="../assets/images/egale.png"></div>
            <!-- <img class="mobile gg" src="../assets/images/gagnez.png"><br />-->

            <div class="sl3">
              <div v-if="s6" @click="s6=false" class="sl3-infos">
                Un e-coffret Wonderbox Parcs d’attractions et zoos d’une valeur unitaire de 89,90€ TTC.
                <br /><br />
                Le e-coffret Wonderbox reçu par email est valable jusqu’au 31/03/2024. Vous pouvez échanger ou prolonger
                gratuitement votre e-coffret, tant que sa date de validité n’est pas dépassée, et autant de fois que
                vous le souhaitez, sauf cas particuliers visés à l’article 7 des conditions générales de vente.
                Vois les termes et conditions complètes sur <a href="https://www.wonderbox.fr/cgv.html" target="_blank">www.wonderbox.fr/cgv.html</a>
              </div>
              <p>et gagnez</p><img @click="s6=true" src="../assets/images/ballon-parc.png">
            </div>
            <div class="sl4">
              <!-- <img src="../assets/images/ou.png"> -->
              <p>OU</p>
            </div>
            <div class="sl5">
                                <div v-if="s8" @click="s8=false" class="sl5-infos">
                Le code reçu vous donne droit à un Livre Photo Style Carré 20x20 cm (ou Livre Photo Couverture Souple carré 20x20cm) de 24 pages GRATUIT, ou -19,95€ sur les autres livres photo, hors album de poche, avec la livraison en Mondial Relay comprise en France Métropolitaine ou -3,99€ sur un autre transporteur. Hors options et pages supplémentaires et uniquement sur Photoweb.fr. Offre non cumulable avec d’autres offres promotionnelles en cours, non compatible avec les tarifs dégressifs. Code unique et personnel valable une seule fois par foyer (même nom, adresse mail et postale), jusqu’au 31/07/2021.   
               <!-- Un Livre Photo Style Carré 20x20 cm de 24 pages GRATUIT, ou -19,95€ sur les autres livres photo, hors Album de poche, avec la livraison en Mondial Relay comprise en France Métropolitaine ou -3,99€ sur un autre transporteur.
                <br /><br />
                Hors options et pages supplémentaires et uniquement sur Photoweb.fr. Offre non cumulable avec d'autres offres promotionnelles en cours, non compatible avec les tarifs dégressifs. Code unique et personnel valable une seule fois par foyer (même nom, adresse mail et postale), jusqu'au 31/07/2021.
                <br /><br />Pour en savoir plus <a href="https://www.photoweb.fr" target="_blank">www.photoweb.fr</a>. -->
              </div> 
              <img @click="s8=true" src="/ballon1.png">
            </div>
          </div>
          <div class="slide">
            <div class="sl1"><img src="/images/super-boite.png">
              <p>La super boîte d'accessoires</p>
            </div>
            <div class="sl2"><img src="../assets/images/egale.png"></div>
            <!-- <img class="mobile gg" src="../assets/images/gagnez.png"><br />-->
            <div class="sl3">
              <div v-if="s7" @click="s7=false" class="sl3-infos">
                Une e-carte cadeaux Cultura Atelier d’une valeur unitaire de 23€ TTC.<br />
                La e-carte cadeau Cultura reçue par email vous permet de régler un atelier créatif Session PARENT & ENFANT
                - Enfant de 4 à 6 ans.<br />
                La e-carte cadeau Cultura est valable jusqu’au 13/01/22. <br />Elle est utilisable en une ou plusieurs
                fois dans tous les magasins Cultura et sur le site cultura.com (presse et billetterie uniquement en
                magasin).
                Les dotations sont non modifiables, non échangeables et non remboursables.
          
              </div>
              <p>et gagnez</p><img @click="s7=true" src="../assets/images/ballon-cultura.png">
            </div>
            <div class="sl4">
              <!-- <img src="../assets/images/ou.png"> -->
              <p>OU</p>
            </div>
            <div class="sl5">
                                <div v-if="s8" @click="s8=false" class="sl5-infos">
                Le code reçu vous donne droit à un Livre Photo Style Carré 20x20 cm (ou Livre Photo Couverture Souple carré 20x20cm) de 24 pages GRATUIT, ou -19,95€ sur les autres livres photo, hors album de poche, avec la livraison en Mondial Relay comprise en France Métropolitaine ou -3,99€ sur un autre transporteur. Hors options et pages supplémentaires et uniquement sur Photoweb.fr. Offre non cumulable avec d’autres offres promotionnelles en cours, non compatible avec les tarifs dégressifs. Code unique et personnel valable une seule fois par foyer (même nom, adresse mail et postale), jusqu’au 31/07/2021.   
               <!-- Un Livre Photo Style Carré 20x20 cm de 24 pages GRATUIT, ou -19,95€ sur les autres livres photo, hors Album de poche, avec la livraison en Mondial Relay comprise en France Métropolitaine ou -3,99€ sur un autre transporteur.
                <br /><br />
                Hors options et pages supplémentaires et uniquement sur Photoweb.fr. Offre non cumulable avec d'autres offres promotionnelles en cours, non compatible avec les tarifs dégressifs. Code unique et personnel valable une seule fois par foyer (même nom, adresse mail et postale), jusqu'au 31/07/2021.
                <br /><br />Pour en savoir plus <a href="https://www.photoweb.fr" target="_blank">www.photoweb.fr</a>. -->
              </div>
              <img @click="s8=true" src="/ballon1.png">
            </div>
          </div>
        </agile>
      </div>
    </div>
  </main>
</template>
<script>
import { VueAgile } from 'vue-agile'
export default {
  name: 'dotation',
  components: {
    agile: VueAgile 
  },
  data() {
      return {
       s1 : false,
       s2 : false,      
       s3 : false,
       s4 : false,
       s5 : false,   
       s6 : false,  
       s7 : false,
       s8 : false                               
      };
    },
    mounted() {

    },
    methods : {
      openHelp : function(id) {
         if(id=='s1') this.s1=true;
      }
    } 
}
</script>

<style lang="scss">
.top-carousel {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  margin-top: 20px;

  @media screen and (max-width: 1200px) {
    width: 1000px;
    text-align: center;
  }

  @media screen and (max-width: 1000px) {
    width: 900px;
  }

  @media screen and (max-width: 900px) {
    width: 98%;
    display: block;
    margin-top: -1%;
  }

  div {
    width: 50%;
    text-align: center;
    font-family: 'Sound Bubble DEMO';
    text-transform: uppercase;
    font-size: 25px;
    color: #22398D;

    @media screen and (max-width: 900px) {
      width: 100%;
      text-align: center;
      font-size: 20px;

      img {
        width: 100px;
        margin-bottom: 0;
      }
    }
  }

  .etgagner {
    margin-left: -80px;

    @media screen and (max-width: 1200px) {
      margin-left: -75px;
    }

    @media screen and (max-width: 900px) {
      display: none;
    }

  }
}

.agile__nav-button--next,
.agile__nav-button--prev {
  background: transparent;
  background-image: url('../assets/images/suivant.png');
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100% 100%;
  border: none;
  width: 64px;
  height: 93px;
  color: transparent;
  cursor: pointer;
  margin-right: 50px;
  position: relative;
  z-index:1;

  @media screen and (min-width: 900px) {
    margin-top:-70px;
  }


  @media screen and (max-width: 1200px) {
    width: 54px;
    height: 73px;
    margin-right: 70px;
        margin-top:-110px;
  }

  @media screen and (max-width: 1000px) {
    margin-right: 20px;
  }

  @media screen and (max-width: 900px) {
    margin-right: 0px;
       margin-top:unset;
  }
}

.agile__nav-button--prev {
  background-image: url('../assets/images/precedent.png');
  margin-left: 80px;

  @media screen and (max-width: 1200px) {
    margin-left: 70px;
  }

  @media screen and (max-width: 1000px) {
    margin-left: 20px;
  }

  @media screen and (max-width: 900px) {
    margin-left: 0px;
  }
}

.carousel {
  width: 1200px;
  margin: 0 auto;
  position:relative;
 
  a {
    color:white;
  }
  
  @media screen and (max-width: 1200px) {
    width: 1000px;
  }

  @media screen and (max-width: 1000px) {
    width: 900px;
  }

  @media screen and (max-width: 1000px) {
    width: 98%;
  }

  .slide {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 1200px;

    @media screen and (max-width: 1200px) {
      width: 100%;
    }

    .sl1 {
      p {
        margin: 0;
        padding: 0;

        color: #22398D;
        font-family: 'Sound Bubble DEMO';
        font-size: 30px;
        text-align: center;
        text-transform: uppercase;

        @media screen and (max-width: 1200px) {
          font-size: 26px;
        }
      }

      img {
        width: 300px;
        @media screen and (max-width: 1200px) {
          width: 250px;
        }
      }

      @media screen and (max-width: 900px) {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
      }
    }

    .sl2 {

      img {
        width: 60px;
        margin-right: 40px;

        @media screen and (max-width: 1200px) {
          width: 50px;
          margin-right: 40px;
        }
      }

      @media screen and (max-width: 900px) {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;

        img {
          margin: 0;
        }
      }
    }

    .sl3 {
      position:relative;
    
      p {
        font-family: 'Sound Bubble DEMO';
        text-transform: uppercase;
        font-size: 42px;
        color: #22398D;
        margin: 0px 15px;

        @media screen and (max-width: 900px) {
          font-size: 30px;
        }

        @media screen and (min-width: 900px) {
          display: none;
        }
      }

      img {
        width: 225px;
        cursor:pointer;
        @media screen and (max-width: 1200px) {
          width: 150px;
        }
      }

      @media screen and (max-width: 900px) {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
      }
    }

    .sl4 {
      p {
        font-family: 'Sound Bubble DEMO';
        text-transform: uppercase;
        font-size: 42px;
        color: #22398D;
        margin: 0px 15px;
        margin-left:5px;

        @media screen and (max-width: 900px) {
          font-size: 30px;
        }
      }

      img {
        width: 55px;
        margin: 0px 15px;
        @media screen and (max-width: 1200px) {
          width: 50px;
        }
      }

      @media screen and (max-width: 900px) {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;

        img {
          margin: 0;
          width: 40px;
        }
      }
    }

    .sl5 {
      img {
        width: 210px;
        cursor:pointer;
        @media screen and (max-width: 1200px) {
          width: 150px;
        }
      }

      @media screen and (max-width: 900px) {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
      }
    }

    .sl3-infos {
        cursor:pointer;
        font-family: 'Myriad Pro Light';
        position: absolute;
        z-index:99999999;

        width:300px;
        color:white;
        font-size:12px;
        line-height:14px;
        padding:35px;
        background-image: url('/images/fond-help.png');
        background-size:100% 100%;


        @media screen and (max-width: 1200px) {
          font-size:10px;
          line-height:12px;
        }

        @media screen and (max-width: 900px) {
          font-size:11px;
          line-height:12px;
          width:220px;
          margin-left:40px;
        }        
    }

    .help3 {
      background-image: url('/images/Sans-titre-3.png');
      width:320px;

         @media screen and (max-width: 1200px) {
          width:345px;
          margin-top:0px;
        }     

           @media screen and (max-width: 900px) {
          width:220px;
          margin-top:0px;
        }           
    }


     .sl5-infos {
        cursor:pointer;
        font-family: 'Myriad Pro Light';
        position: absolute;
        z-index:99;
        width:300px;
        color:white;
        font-size:12px;
        line-height:14px;
        padding:35px;
        margin-left:-130px;
  
        background-image: url('/images/font-help2.png');
        background-size:100% 100%;


          @media screen and (max-width: 1200px) {
          margin-left:-150px;  
          width:260px;
          font-size:10px;
          line-height:12px;
          }      

          @media screen and (max-width: 900px) {
            margin:0;
            width:220px;
          font-size:11px;
          line-height:12px;
          margin-top:-40px;
          margin-left:20px;
          }          
    }   
  }
}

.agile__actions {
  margin-top: -200px;

  @media screen and (max-width: 1200px) {
    margin-top: -150px;
  }

  @media screen and (max-width: 900px) {
    position: absolute;
    width: 100%;
    top: 28%;
  }

  @media screen and (max-width: 375px) {
    top: 28%;
  }

  @media screen and (max-width: 360px) {
    top: 26%;
  }

  @media screen and (max-width: 320px) {
    top: 25%;
  }
}

.agile__dots {
  display: none;
}

.carousel .slide {
  width:fit-content;
  @media screen and (min-width: 900px) {
  height:450px;
  margin-top:-70px;
  }
  
  @media screen and (max-width: 900px) {
    display: unset;
    text-align: center;
  }
}

.gg {
  width: 120px;
  margin-bottom: 20px;
}

.dota-wrapper {
    @media screen and (min-width: 900px) {
  height:450px;
    }
  @media screen and (max-width: 900px) {
    background-image: url('../assets/images/top-dotation-mobile.jpg');
    background-size: 100% auto;
    background-position: top center;
  }
}
</style>